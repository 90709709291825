// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "channels";

Rails.start();

window.addEventListener("load", () => {
  function fogClickListener() {
    const fogElement = document.querySelector(".fog");
    fogElement.removeEventListener("click", fogClickListener);
    fogElement.removeEventListener("touchend", fogClickListener);
    removeModal();
  }

  function addModal() {
    const modalTitle = "Congratulations 🎉";
    const modalBodyParagraphs = [
      "We have sent you a confirmation email.",
      "After confirmation you will get access to leaderboard and will get your unique referral link.",
    ];
    window.scrollTo(0, 0);
    const html = document.querySelector("html");
    const body = document.querySelector("body");
    html.classList.add("modal-block");
    body.classList.add("modal-block");

    const fogElement = document.createElement("div");
    fogElement.classList.add("fog");
    fogElement.id = "fog";
    body.appendChild(fogElement);

    const modalElement = document.createElement("div");
    modalElement.classList.add("modal");
    modalElement.id = "modal";

    modalElement.innerHTML =
      `<h2 class="modal-title">${modalTitle}</h2>` +
      modalBodyParagraphs
        .map((text) => `<p class="modal-body">${text}</p>`)
        .join(" ");

    body.prepend(modalElement);
    fogElement.addEventListener("click", fogClickListener);
    fogElement.addEventListener("touchend", fogClickListener);
  }

  function removeModal() {
    const html = document.querySelector("html");
    const body = document.querySelector("body");
    const fogElement = document.querySelector("#fog");
    const modalElement = document.querySelector("#modal");

    html.classList.remove("modal-block");
    body.classList.remove("modal-block");
    fogElement.remove();
    modalElement.remove();
  }

  const element = document.querySelector("#email-form");
  element.addEventListener("ajax:success", (event) => {
    addModal();
  });

  element.addEventListener("ajax:error", () => {
    alert("Error");
  });
});
